import React,{forwardRef,useRef,useEffect,useState,useCallback} from 'react'
import styles from './narrative.module.scss'
import NarrativeMenuItem  from './NarrativeMenuItem'

import {ReactComponent as ArrowLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as ArrowRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as Close} from 'assets/icons/close.svg'
import {ReactComponent as Grip} from 'assets/icons/grip.svg'
import {ReactComponent as Dragger} from 'assets/icons/dragger.svg'

import gsap,{Power3} from 'gsap'
import Globals from 'system/Globals'

const Controls =forwardRef((props,ref)=>{
    // let ref = useRef()
    let refX=useRef(0)
    let refY=useRef(0)
    let refBtmDrawer = useRef()
    const [drawerOpen,setDrawerOpen ] = useState(false)

    
   

    useEffect(()=>{
        gsap.to(ref.current,{opacity:1,duration:0.35,delay:0.5})
        gsap.from(ref.current,{scale:0.75,duration:0.35,delay:0.5})
        

        return ()=>{

         }
    },[ref])

    useEffect(()=>{
        // console.log('props', props, ref)
        // if (props && ref) {
        //     window.addEventListener('keydown', onArrows)
          
        // }
        

    },[props, ref])

    
    useEffect(()=>{
        let dragger = document.querySelector('#controlsDragger');
        if(drawerOpen){
            let theight = refBtmDrawer.current.clientHeight;
            refBtmDrawer.current.style.height='auto'
            let height = refBtmDrawer.current.clientHeight;
            refBtmDrawer.current.style.height=theight+'px'
            gsap.to(refBtmDrawer.current,{height:height,ease:Power3.easeInOut,duration:0.5,onComplete:(ele)=>{ele.style.height='auto';},onCompleteParams:[refBtmDrawer.current]})
            gsap.to(dragger,{rotationX: 0,duration:0.25})
        }
        else{
            gsap.to(refBtmDrawer.current,{height:'32px',ease:Power3.easeInOut,duration:0.5})
            gsap.to(dragger,{rotationX: 180,duration:0.25})
        }
    },[drawerOpen])
    function onDown(e){
        
        if(e.touches){
            refX.current=e.touches[0].clientX
            refY.current=e.touches[0].clientY
        } else{
            refX.current=e.clientX
            refY.current=e.clientY
        }
        let onMove=(evt)=>{
            // evt.preventDefault();
            
            let xpos,ypos;
            if(evt.touches){
                xpos=evt.touches[0].clientX
                ypos=evt.touches[0].clientY
            } else{
                xpos=evt.clientX
                ypos=evt.clientY
            }
            let diffX = xpos-refX.current
            let diffY = ypos-refY.current
            let style= getComputedStyle(ref.current)
            refX.current=xpos
            refY.current=ypos
            ref.current.style.left = (parseFloat(style.getPropertyValue('left'))+diffX)+'px'
            ref.current.style.bottom = (parseFloat(style.getPropertyValue('bottom'))-diffY)+'px'
            
        }
        let onUp=()=>{
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }
        window.addEventListener('mousemove',onMove)
        window.addEventListener('touchmove',onMove)
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)
    }



    function pageChange(index){
        const page = props.flatMenuItems[index].page;

        console.log('NarrativeControls pageChange', page, props.model.transition)
        props.onScrollEvent(page, props.model.transition)
    }

    function onClickLeft(){
        setDrawerOpen(false)
        console.log('onClickLeft')
        const pageObj = props.flatMenuItems[parseInt(props.pageIndex)-1];
        if (pageObj) {
            props.onScrollEvent(pageObj.page, props.model.transition)
            // props.setCurrentPage(props.pageIndex - 1)
        }

    }
    function onClickRight(){
        console.log('onClickRight', parseInt(props.pageIndex), props.flatMenuItems[parseInt(props.pageIndex)+1])
        setDrawerOpen(false)
        const pageObj = props.flatMenuItems[parseInt(props.pageIndex)+1];
        if (pageObj) {
            // console.log('props.flatMenuItems', props.flatMenuItems, pageObj.page, parseInt(props.pageIndex)+1)

            props.onScrollEvent(pageObj.page, props.model.transition)
            // props.setCurrentPage(props.pageIndex + 1)
        }
        
    }
    function onClickClose(){
        gsap.to(ref.current,{opacity:0,duration:0.25, onComplete:()=>{props.setShowControls(false)}})
        gsap.to(ref.current,{scale:0.75,duration:0.25})

    }

    function onClickLink(evt){
        
        setDrawerOpen(false)
        let index = evt.target.getAttribute("index")
        pageChange(index);


        if (props.socketPrefs.mode === "isBroadcast") {
            const page = props.model.menu_links[index].page;
            console.log('onClickLink', page, props)
            props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-link?page=' + page]);
        }
        
    }
    function onClickModule(evt){
        let index = evt.currentTarget.getAttribute("index")
        console.log("onClickModule", props.model.modules[index], props.model)
        props.onSelectModule(new CustomEvent('select-module',{detail:{index:index,module:props.modules[index]}}))
        // ref.current.dispatchEvent(new CustomEvent('select-module',{detail:{index:index,module:props.modules[index]}}))
    }

    function onClickBtmDrawer(e){
        setDrawerOpen(!drawerOpen)
    }

    useEffect(() => {
        console.log('props.socketPrefs.room', props.socketPrefs.room)
        if (props.socketPrefs.room !== null) {
            // gsap.to(ref.current,{opacity:0,duration:0.25, onComplete:()=>{props.setShowControls(false)}})
            // gsap.to(ref.current,{scale:0.75,duration:0.25})
        }
    }, [props.socketPrefs])

    const onExpandSubs =useCallback(evt=>{
        console.log(evt)
        let openEle = refBtmDrawer.current.querySelector(`.${styles.controlsMenuLink}[open]:not([index="${evt}"])`)
        if(openEle){
            openEle.dispatchEvent(new CustomEvent('close'))
        }

    

    },[])

    return (

        <div className={`${styles.controlsContainer}`}  ref={ref}>
            {props.flatMenuItems && props.flatMenuItems.length > 0 &&  <div className={`${styles.controlsPill}`}>
                <ArrowLeft className={`${(props.pageIndex > 0) ? "" : styles.off }`} onClick={onClickLeft}></ArrowLeft>
                <span className={`${styles.controlsPillLine}`} />
                <ArrowRight className={`${(props.pageIndex < (props.flatMenuItems.length - 1)) ? "" : styles.off }`} onClick={onClickRight}></ArrowRight>

               
            </div>}
        </div>
    )


})

export default Controls