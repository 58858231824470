import M from '../../system/utils/M'
import gsap,{Power0,Power1,Cubic} from 'gsap'
import {CustomEase} from "gsap/CustomEase"

gsap.registerPlugin(CustomEase);

const EasingFunctions = {
    // no easing, no acceleration
    linear: t => t,
    // accelerating from zero velocity
    easeInQuad: t => t*t,
    // decelerating to zero velocity
    easeOutQuad: t => t*(2-t),
    // acceleration until halfway, then deceleration
    easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
    // accelerating from zero velocity 
    easeInCubic: t => t*t*t,
    // decelerating to zero velocity 
    easeOutCubic: t => (--t)*t*t+1,
    easeInOutCubic: t => t<.5 ?t*t*t:t => (--t)*t*t+1,
    // acceleration until halfway, then deceleration 
    easeInOutQuad2: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
    // accelerating from zero velocity 
    easeInQuart: t => t*t*t*t,
    // decelerating to zero velocity 
    easeOutQuart: t => 1-(--t)*t*t*t,
    // acceleration until halfway, then deceleration
    easeInOutQuart: t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
    // accelerating from zero velocity
    easeInQuint: t => t*t*t*t*t,
    // decelerating to zero velocity
    easeOutQuint: t => 1+(--t)*t*t*t*t,
    // acceleration until halfway, then deceleration 
    easeInOutQuint: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t,
    default: t => t<.5 ? 2*t*t : -1+(4-2*t)*t
}

export function getAnimationModel(ele,model,type){

    
    
    let M= { type:model.typeName, animations:[], offsetLeft:0,
        offsetTop:0,
       }

    M.offsetLeft=ele.getBoundingClientRect().x
    M.offsetTop=ele.getBoundingClientRect().y
    
    let t=type?type:'animations'
    if(model[t] === undefined) return null

   


    model[t].forEach((m)=>{

        let animModel ={
        prop:'opacity',
            start:0,
            end:1,
            s:0,
            e:1,
            d:0,
            dur:1,
            ease:'easeInOutQuad'
        }
        
        if(m.property!==undefined)
            animModel.prop=m.property
        if(m['startParallax']!==undefined)
            animModel.s= m['startParallax']
        if(m['endParallax']!==undefined)
            animModel.e = m['endParallax']
        if(m.start!==undefined){
            let unit = String(m.start).replace(/[0-9]|-/g, '');
            if(unit==="%" && m.property==='opacity')
                animModel.start=parseFloat(m.start)/100
            else
                animModel.start=m.start
        }
        if(m.end!==undefined){
            let unit = String(m.end).replace(/[0-9]|-/g, '');
            if(unit==="%" && m.property==='opacity')
                animModel.end=parseFloat(m.end)/100
            else
                animModel.end=m.end
        }
        if(m.ease)
            animModel.ease=m.ease
        if(m.delay)
            animModel.d=m.delay
        if(m.duration)
            animModel.dur=m.duration
        
        M.animations.push(animModel)
        if(model.typeName==='page' || type==='stateAnimations'){
            setInitialAnimIntersectState(ele,M,t)
        }
        
    })
// console.log('M', M)
return M


}

export function animateParallax(ele,model,scrollLeft,sw,refLinkedupdates){
    if(!ele)return
    
    let transform=""
    let filter=""
    // console.log(scrollLeft,scale,model.offsetLeft)
    let per =  (model.offsetLeft-scrollLeft)/sw
    
    model.animations.forEach((a)=>{
        let p = 1-((M.clamp(per,a.e,a.s)  - a.e )/ (a.s - a.e))
        let eq = a.ease ? EasingFunctions[a.ease] : EasingFunctions['easeInOutQuad']
      
        if(a.prop == null){console.warn('null aniamtion propety passed',a);return}
        if(a.prop.indexOf('translate')===0) {
            let unit = `${a.start}`.replace(/[0-9]|-/g, ''); 
            let val=parseFloat(a.start)
            val = val + (eq(p) * (parseFloat(a.end)-parseFloat(a.start)))
            //convert transform % TO PX
            if(unit==='%'){
                val = val/100 * sw
                if(a.prop.indexOf('translate')===0){ 
                    unit='px'
                }
               
            }

            transform = `${transform} ${a.prop}(${val}${unit})`
        }
        else if(a.prop.indexOf('scale')===0){
            let unit = `${a.start}`.replace(/[0-9]|-/g, ''); 
            let val=parseFloat(a.start)
            val = val + (eq(p) * (parseFloat(a.end)-parseFloat(a.start)))
            if(unit==='%'){
                val = val/100 
            }
            // console.log(val,unit)
            transform = `${transform} scale(${val})`
        }
        else if(a.prop==="blur"){
            let unit = `${a.start}`.replace(/[0-9]|-/g, ''); 
            let val = parseFloat(a.start) + (eq(p) * (parseFloat(a.end)-parseFloat(a.start)))
            if(val>0.001)
            filter = `${filter} ${a.prop}(${val}${unit})`
        }
        else if(a.prop==="drop-shadow"){
            let def = `${a.start}`.replace(/[0-9]|-/g, ''); 
            let unit = def.split(' ')[0]
            let color = def.split(' ')[1]
            let val = parseFloat(a.start) + (eq(p) * (parseFloat(a.end)-parseFloat(a.start)))
            if(val>0.001)
            filter = `${filter} ${a.prop}(0 0 ${val}${unit} ${color})`
        }
        else{
            ele.style[a.prop] = a.start + (eq(p) * (a.end-a.start))
            if(refLinkedupdates)
            refLinkedupdates.current.forEach(el=>{ el.style[a.prop]=ele.style[a.prop] })
        }
        
    })
        ele.style.transform = transform
        ele.style.filter = filter
        
        if(refLinkedupdates){
        refLinkedupdates.current.forEach(el=>{
            el.style.transform=transform
            el.style.filter=filter
        })
        }
}

function strToEasing(str){
    
    let ease = CustomEase.create("custom", "M0,0,C0.5,0,0.3,1,1,1")
    if(str==='linear')
    ease= Power0.easeNone
    else if (str === 'easeInQuad')
    ease=Power1.easeIn
    else if (str === 'easeOutQuad')
    ease=Power1.easeOut
    else if (str === 'easeInOutQuad')
    ease=Power1.easeInOut
    else if (str === 'easeInOutCubic')
    ease=Cubic.easeInOut
    else if (str === 'easeInCubic')
    ease=Cubic.easeIn
    else if (str === 'easeOutCubic')
    ease=Cubic.easeOut
    else if (str === 'easeInOutQuart')
    ease=Cubic.easeOut
    else if (str === 'elastic')
    ease='elastic.out(1.2, 0.75)'
    
    return ease
}

export function getAnimationIntersectParams(obj,ele,a,val){

    // let val = a.end
    let prop = a.prop
    let unit = `${val}`.replace(/[0-9.]|-/g, ''); 
    val=String(val).replace(unit,'')
    // let obj={duration:a.dur,delay:a.d,ease:strToEasing(a.ease)}
    
    if(a.prop === "translateX"){ 
        prop="x"
        if(unit==='%') {
            val = parseFloat(val)/100 * ele.parentElement.clientWidth
            // console.log('percent'+val)
            unit='px'
        }
        obj[prop]=val+unit
    }
    else if(a.prop === "translateY"){ 
        prop="y"
        if(unit==='%') {
            val = parseFloat(val)/100 * ele.parentElement.clientHeight
            unit='px'
        }
        obj[prop]=val+unit
    }
    else if (a.prop==='scale'){
        val=  parseFloat(val)
        if(unit==='%')val=val/100
        if(val===0)val=0.001
        obj[prop] = `${val}`
    }
    else if(a.prop === "opacity"){ 
        val=  parseFloat(val)
        if(unit==='%')val=val/100
        if(val===0)val=0.001
        obj[prop] = `${val}`
    }
    else if(prop === "blur" ){
        obj.filter = `${prop}(${val}${unit})`
    }
    else if(prop==="drop-shadow"){
        obj.filter = `${prop}(0 0 ${val}${unit})`
        // console.log("DROP ",obj, obj.filter)
    }
    else{
        obj[prop]=val+unit
    }

    return {params:obj,prop:prop}
}


export function setInitialAnimIntersectState(ele,model){

     
    
    model.animations.forEach((a)=>{
        
        let obj=getAnimationIntersectParams({},ele,a,a.start)
        gsap.killTweensOf(ele)
        gsap.set(ele,obj.params)
        // console.log('setInitialAnimIntersectState', ele,obj.params)
    })
    
}

export function animateStateTransition(container,ele,model,oncomplete,oncompleteparams){
    
    let lastAnim = null
    let maxDur = 0
    
    if(typeof oncomplete ==='function'){
        model.animations.forEach((a,index)=>{if(a.dur >maxDur){
            maxDur=a.dur
            lastAnim=index
        }})
    }

    let opacityAnim = null

    model.animations.forEach((a,index)=>{
       
        let objAnim=getAnimationIntersectParams({duration:a.dur,delay:a.d,ease:strToEasing(a.ease)},ele,a,a.end)
        
        let obj=objAnim.params
        
        if(objAnim.prop === "opacity"){
            opacityAnim=Object.assign(Object.assign({},obj),{opacity:0,delay:0})
            gsap.to(container.firstElementChild,opacityAnim)
        }

        if(lastAnim===index && typeof oncomplete ==='function'){
            obj.onComplete=oncomplete
            obj.onCompleteParams=oncompleteparams
        }
        
        gsap.to(ele,obj)
    
    })
    return opacityAnim
}

export function animateIntersect(ele,model,refLinkedupdates){

    // console.log('animateIntersect', ele,model,refLinkedupdates)  
    gsap.to(ele.parentElement, {opacity: 1}); 


    model.animations && model.animations.forEach((a,)=>{
       let objAnim=getAnimationIntersectParams({duration:a.dur,delay:a.d,ease:strToEasing(a.ease)},ele,a,a.end)
        let obj=objAnim.params
        // console.log('animateIntersect end0', ele,obj)   
        if(refLinkedupdates){
            obj.onUpdate=(ele,prop)=>{
                refLinkedupdates.current.forEach( el=>{                    
                    let parms={}
                    parms[prop]=gsap.getProperty(ele,prop)
                    gsap.killTweensOf(el)
                    gsap.set(el,parms)
                })
            }
            obj.onUpdateParams=[ele,objAnim.prop]
        }
        // console.log('animateIntersect end', ele,obj)   
        gsap.to(ele,obj)
    })
}



export function setFinalAnimIntersectState(ele,model){
    // console.log("Final", ele)
    model.animations.forEach((a)=>{
        // let obj={}
        // let val = a.end
        // let prop = a.prop
        // console.log(prop,val)
        // let unit = `${val}`.replace(/[0-9]|-/g, '');
        
        // val=String(val).replace(unit,'')
            
        // if(a.prop === "translateX"){
        //     prop="x"
        //     if(unit==='%') {
        //         val = parseFloat(val)/100 * ele.parentElement.clientWidth
        //         unit='px'
        //     }
        //     obj[prop]=val+unit
        // }
        // else if(a.prop === "translateY"){
        //     prop="y"
        //     val=val.replace('unit','')
        
        //     if(unit==='%') {
        //         val = parseFloat(val)/100 * ele.parentElement.clientHeight
        //         unit='px'
        //     }
        //     obj[prop]=val+unit
        // }
        // else if(prop === "blur" ){
        //     obj.filter = `${prop}(${val}${unit})`
        // }
        // else if(prop==="drop-shadow"){
        //     obj.filter = `${prop}(${val}${unit})`
        // }
        // else if(prop==="scale"){
        //     val=  parseFloat(val)
        //     if(unit==='%')val=val/100
        //     if(val===0)val=0.001
        //     obj[prop] = `${val}`
        // }
        // else if(prop==="opacity"){
        //     val=  parseFloat(val)
        //     if(unit==='%')val=val/100
        //     if(val===0)val=0.001
        //     obj[prop] = `${val}`
        // }
        // else{
        //     if(val===0)val=0.001
        //     obj[prop]=val+unit
        // }
        let obj=getAnimationIntersectParams({},ele,a,a.end)
        
        // if(prop == 'scale')
        // console.log(prop,obj,unit,a)
        
        gsap.killTweensOf(ele)
        // console.log(ele)

        gsap.set(ele, obj.params)
    })
}

const animate =  (ele, model)=>{}

export default animate